import { Box, Divider, Grid, ThemeProvider, Typography } from "@mui/material"
import { theme } from "../styles/MUI/Theme"

export const ServicesPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box minHeight={"calc(100vh - 172px - 75px)"} marginTop="100px">
                <Typography variant="h2" sx={{ fontWeight: "700", justifyContent: "center", display: "flex", my: "20px", textAlign: "center" }}> Unete a Nosotros</Typography>
                <p style={{textAlign:"center"}}>En armatuproyecto.cl te ayudamos a ordenar tu idea y buscar cómo hacerla realidad a través de fondos concursables. Para ello, tenemos planes para acompañarte en el proceso</p>
            </Box>
        </ThemeProvider>
    )
}