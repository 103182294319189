import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import { AppBar, Box, Divider, Drawer, IconButton, Menu, MenuItem, Toolbar, Typography,ThemeProvider } from "@mui/material"
import { Link } from "react-router-dom"
import "../../styles/CSS/navbar.css"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BallotIcon from '@mui/icons-material/Ballot';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from "../img/Logo.png"
import { theme } from '../../styles/MUI/Theme';

const navbarlinks = [
    {
        "title": "Inicio",
        "link": "/",
    },
    // {
    //     "title": "Nuestros Servicios",
    //     "link": "/Servicios",
    // },
    // {
    //     "title": "Quienes somos",
    //     "link": "/quienesSomos",
    // },
    {
        "title": "Contacto",
        "link": "/contact",
    }
]

export const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <ThemeProvider theme={theme}>
                <AppBar position="fixed" sx={{ backgroundColor: "#ebebf0" }}>
                    <Box sx={{ display: "flex", justifyContent: "end",backgroundColor:"#01180A" }}>
                        <IconButton>
                            <WhatsAppIcon color='primary'/>
                        </IconButton>
                        <IconButton href='https://forms.gle/EYevtdj8UaMxGPb5A'>
                            <BallotIcon color='primary' />
                        </IconButton>
                        <IconButton>
                            <MailOutlineIcon color='primary'/>
                        </IconButton>
                        <IconButton href='https://instagram.com/armatuproyecto?igshid=YmMyMTA2M2Y='>
                            <InstagramIcon color='primary'/>
                        </IconButton>
                    </Box>
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100vw" }}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Drawer
                                    id="menu-appbar"
                                    keepMounted
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' }
                                    }}
                                >
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "300px", marginBottom: "20px" }}>
                                        <img src={logo} style={{ maxWidth: "75px" }} />
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleCloseNavMenu}
                                            sx={{ mx: "20px" }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    {navbarlinks.map((page) => (
                                        <a href={page.link} style={{ textDecoration: "none", color: "black", display: "flex", alignItems: "center" }}>
                                            <CircleIcon sx={{ color: "#23837d" }} />
                                            <MenuItem key={page.title}>
                                                <Typography variant='h6' textAlign="center">{page.title}</Typography>
                                            </MenuItem>
                                        </a>
                                    ))}
                                </Drawer>
                            </Box>
                            <a href="/" style={{ textDecoration: "none", alignItems: "center", display: "flex" }}>
                                <img src={logo} style={{ maxWidth: "75px" }} />
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <h2 className="fw-bold namedisplay" style={{ color: "black", fontWeight: "700" }}>ARMA TU PROYECTO</h2>
                                </Box>
                            </a>
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <ul>
                                    {navbarlinks.map((pages) => (
                                        <li>
                                            <a href={pages.link}>
                                                {pages.title}
                                            </a>
                                        </li>

                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
        </ThemeProvider>
    )
}