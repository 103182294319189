import { Box, Divider, Grid, ThemeProvider, Typography } from "@mui/material"
import { Fondos } from "../components/Home/Fondos"
import { Hero } from "../components/Home/Hero"
import { TeamCard } from "../components/Home/Team"
import Romi from "../components/img/Romi2.jpg";
import Christian from "../components/img/Christian2.jpg";
import { theme } from "../styles/MUI/Theme";

const CRomi = ["Fonoaudióloga de profesión, diplomada en Gobierno y Gestión Pública.",
    "Profesional con experiencia en evaluación y gestión de proyectos de Organizaciones comunitarias y medios de comunicación de la región de Valparaíso.",
    "Manejo de plataformas gubernamentales para postulación a fondos concursables.",
    "Experiencia en diseño y formulación de proyectos."]

const CCristian = ["Ingeniero Civil Industrial", "Profesional dedicado al área de proyectos y gestión.", "Especialización en formulación y evaluación de proyectos públicos y privados."]

export const HomePage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box minHeight={"100vh"} marginTop="75px">
                <Hero />
                <Divider />
                <Typography variant="h3" sx={{ fontWeight: "700", justifyContent: "center", display: "flex", my: "20px", textAlign: "center" }}>¿Quienes pueden postular a fondos concursables?</Typography>
                <Fondos />
                <Divider />
                <Typography variant="h3" sx={{ fontWeight: "700", justifyContent: "center", display: "flex", my: "20px", textAlign: "center" }}>Nuestro Equipo</Typography>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    my={"20px"}
                >
                    <TeamCard nombre="Romina Vásquez" caracteristicas={CRomi} img={Romi} />
                    <TeamCard nombre="Christian Herrera" caracteristicas={CCristian} img={Christian} />
                </Grid>

            </Box>
        </ThemeProvider>

    )
}