import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ebebf0",
      contrastText:"#23837d"
    },
    secondary: {
      main:"#23837d",
      contrastText:"#ebebf0"
    },
  },
});
