import { Box, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Footer } from '../components/Footer/Footer';
import { Navbar } from '../components/Nav/Navbar';
import { ContactPage } from '../pages/ContactPage';
import { ErrorPage } from '../pages/ErrorPage';
import { HomePage } from '../pages/HomePage';
import { ServicesPage } from '../pages/ServicesPage';
import { TeamPage } from '../pages/TeamPage';


function AppRouter() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/Servicios" element={<ServicesPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/quienesSomos" element={<TeamPage />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
            <Divider />
            <Footer />
        </BrowserRouter>


    );
}

export default AppRouter;