import { Box, Divider, Grid, ThemeProvider, Typography } from "@mui/material"
import { theme } from "../styles/MUI/Theme"
import Error from "../components/img/Error.svg"

export const ErrorPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box minHeight={"calc(100vh - 172px - 75px)"} marginTop="100px" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center">
                <Typography variant="h2" sx={{ fontWeight: "700", justifyContent: "center", display: "flex", my: "20px", textAlign: "center" }}>Creo que estas perdido</Typography>
                <p style={{textAlign:"center"}}>La pagina a la cual intentas acceder no existe</p>
                <img src={Error} style={{maxWidth:"900px"}} />
            </Box>
        </ThemeProvider>
    )
}