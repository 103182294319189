import { Box, Grid, Typography } from "@mui/material"
import corfo from "../img/corfo.png"
import chileAtiende from "../img/chile-atiende.png"
import fosis from "../img/fosis.png"
import sii from "../img/sii.png"
import tesoreria from "../img/tesoreria.png"
import sercotec from "../img/sercotec.png"

const lista = [
    {
        "img": corfo,
        "link": "https://www.corfo.cl/sites/cpp/homecorfo",
    },
    {
        "img": chileAtiende,
        "link": "https://www.chileatiende.gob.cl/",
    },
    {
        "img": fosis,
        "link": "https://www.fosis.gob.cl/",
    },
    {
        "img": sii,
        "link": "https://homer.sii.cl/",
    },
    {
        "img": tesoreria,
        "link": "https://www.tgr.cl/",
    },
    {
        "img": sercotec,
        "link": "https://www.sercotec.cl/",
    }
]


export const Footer = () => {
    return (
        <Box>
            <Box sx={{ backgroundColor: "#6cc3d5" }}>
                
            </Box>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                padding={"30px"}
            >
                {lista.map((imagen) => (
                    <a href={imagen.link}>
                        <img src={imagen.img} style={{ padding: "24px", maxWidth: "170px" }} />
                    </a>

                ))}
            </Grid>
        </Box>
    )
}