import { Box, Divider, Button, Grid, ThemeProvider, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BallotIcon from '@mui/icons-material/Ballot';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CircleIcon from '@mui/icons-material/Circle';
import InstagramIcon from '@mui/icons-material/Instagram';
import { theme } from "../styles/MUI/Theme"

export const ContactPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box minHeight={"calc(100vh - 173px - 75px)"} marginTop="75px"
                sx={{ background: "linear-gradient(90deg, rgba(208,170,114,1) 0%, rgba(35,131,125,1) 70%)" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    sx={{ minHeight: "600px" }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: "20px" }}>
                        <Typography variant="h3" sx={{ fontWeight: "700", color: "#ebebf0" }}>Contactanos</Typography>
                        <Typography variant="h6" sx={{ fontWeight: "700", color: "#ebebf0" }}>Estamos emocionados por escuchas tus ideas</Typography>
                        <p style={{ color: "#ebebf0" }}>Cualquier consulta sobre algun servicio o apoyo que necesites,<br /> hablanos por medio de whatsapp, correo
                            <br />Tambien puedes completar nuestra encuesta <br />  y te enviaremos todo lo que te pueda ser util</p>
                        <List sx={{ display: "flex", flexDirection: "column"}}>
                                <ListItem sx={{px:"0 !important"}}>
                                    <ListItemIcon>
                                        <CircleIcon sx={{ color: "#ebebf0" }} />
                                    </ListItemIcon>
                                    <ListItemText sx={{ fontWeight: "700", color: "#ebebf0" }}>
                                        +5699999999
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{px:"0 !important"}}>
                                    <ListItemIcon>
                                        <CircleIcon sx={{ color: "#ebebf0" }} />
                                    </ListItemIcon>
                                    <ListItemText sx={{ fontWeight: "700", color: "#ebebf0" }}>
                                        correo@gadasda.cl
                                    </ListItemText>
                                </ListItem>
                        </List>

                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: "20px" }}>
                        <Button variant="contained" size="large" sx={{ m: "20px", borderRadius: "20px" }} startIcon={<WhatsAppIcon />}>Whatsapp</Button>
                        <Button variant="contained" size="large" sx={{ m: "20px", borderRadius: "20px" }} startIcon={<InstagramIcon />}>Instagram</Button>
                        <Button variant="contained" size="large" sx={{ m: "20px", borderRadius: "20px" }} startIcon={<MailOutlineIcon />}>Correo</Button>
                        <Button variant="contained" size="large" sx={{ m: "20px", borderRadius: "20px" }} startIcon={<BallotIcon />}>Completa nuestra encuesta</Button>
                    </Box>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}