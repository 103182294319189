import { Box, Grid, Typography,Button } from "@mui/material"
import heroimg from "../img/Hero2.svg"

export const Hero = () => {
    return (
        <Box sx={{ minHeight: "700px" , background: "linear-gradient(45deg, rgba(208,170,114,1) 0%, rgba(35,131,125,1) 70%)" }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                padding={"30px"}
            >
                <img src={heroimg} style={{ maxWidth: "700px" }} />
                <Box paddingX={"20px"}>
                    <Typography variant="h2" sx={{fontWeight:"700", color:"#ebebf0"}}>Arma tu Proyecto</Typography>
                    <p style={{maxWidth:"500px", color:"#ebebf0"}}>Te invitamos a compartir tu idea para fortalecer tu organización o empresa para que podamos identificar los fondos concursables mas adecuados para alcanzar tus metas.</p>
                    <Box>
                        <Button variant="outlined" size="large"  sx={{m:"20px", borderRadius:"20px"}}>Unetenos ya!</Button>
                        <Button href="https://forms.gle/EYevtdj8UaMxGPb5A" variant="contained" size="large" sx={{m:"20px",  borderRadius:"20px"}}>Contactanos</Button>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}