import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';


export const Fondos = () => {
    return (
        <Box padding={"24px"} sx={{ display: "flex", justifyContent: "center", flexDirection:"column", textAlign:"center" }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                my="20px"
            >
                <Box sx={{m:"20px", display: "flex", flexDirection: "column", alignItems:"center",mx:"30px", backgroundColor:"#23837d", padding:"20px", borderRadius: "10px", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important"}}>
                    <PersonIcon sx={{ fontSize: "100px", color: "#ebebf0" }} />
                    <Typography color="#ebebf0" variant="h6" sx={{fontWeight:"700"}} textAlign={"center"}>PERSONAS NATURALES</Typography>
                </Box>
                <Box sx={{m:"20px", display: "flex", flexDirection: "column", alignItems:"center",mx:"30px", backgroundColor:"#23837d", padding:"20px", borderRadius: "10px", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important"}}>
                    <GroupIcon sx={{ fontSize: "100px", color: "#ebebf0" }} />
                    <Typography color="#ebebf0" variant="h6" sx={{fontWeight:"700"}} textAlign={"center"}>PERSONAS JURÍDICAS</Typography>
                </Box>
                <Box sx={{m:"20px", display: "flex", flexDirection: "column", alignItems:"center",mx:"30px", backgroundColor:"#23837d", padding:"20px", borderRadius: "10px", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important"}}>
                    <StoreIcon sx={{ fontSize: "100px", color: "#ebebf0" }} />
                    <Typography color="#ebebf0" variant="h6" sx={{fontWeight:"700"}} textAlign={"center"}>ORGANIZACIONES SOCIALES</Typography>
                </Box>
            </Grid>
            <Typography variant="h6">Si quieres saber qué opciones de financiamiento tienes, contáctanos aquí</Typography>
            <Box my="30px">
                <Button href="https://forms.gle/EYevtdj8UaMxGPb5A" color="secondary" variant="contained" size="large"
                 sx={{ borderRadius:"20px", maxWidth:"300px",background: "linear-gradient(90deg, rgba(208,170,114,1) 0%, rgba(35,131,125,1) 70%)"}}>Contactanos</Button>   
            </Box>
            
        </Box>
    )
}