import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';
import Typography from "@mui/material/Typography"

interface props{
    caracteristicas : Array<String>,
    nombre:String,
    img:string
}

export const TeamCard = (props:props) => {
    return (
        <Box sx={{ p: "10px", m: "10px", borderRadius: "10px", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important", minHeight: "640px", display: "flex",
         alignItems: "center", flexDirection: "column", maxWidth:"500px" }}>
            <img src={props.img}
                style={{ borderRadius: "50%", border: "5px solid #23837d", maxHeight:"200px", minWidth:"200px" }} />
            <Typography variant="h5" fontWeight={700} my="10px">{props.nombre}</Typography>
            <List sx={{ display: "flex", flexDirection: "column" }}>
                {props.caracteristicas.map((caracter) => (
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon sx={{color:"#23837d"}} />
                        </ListItemIcon>
                        <ListItemText>
                            {caracter}
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}